import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AUTH_DATA_SELECTORS } from '../../../state/authentication/auth.selectors';
import { StorageKeysEnum } from '../../../feature/shared/enums/storage-keys.enum';
import { NAVIGATION_PATH } from '../../../feature/shared/constants/navigation-paths.const';

export const authGuard: CanActivateFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(AUTH_DATA_SELECTORS.selectIsAuthenticated).pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      }

      // TODO : RESOLVE WITHOUT LOCALSTORAGE CHECK
      const token = typeof window !== 'undefined' ? localStorage.getItem(StorageKeysEnum.TOKEN) : null;
      return token ? true : router.createUrlTree([NAVIGATION_PATH.LOGIN]);
    }),
  );
};
