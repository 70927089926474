import { inject, Injectable } from '@angular/core';
import { FaIconLibrary, IconDefinition } from '@fortawesome/angular-fontawesome';

import {
  faAnglesUpDown as farAnglesUpDown,
  faArrowLeft as farArrowLeft,
  faCashRegister as farCashRegister,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faClose as farClose,
  faCopy as farCopy,
  faExclamationCircle as farExclamationCircle,
  faExclamationTriangle as farExclamationTriangle,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFileExport as farFileExport,
  faFileLines as farFileLines,
  faGear as farGear,
  faGearComplex as farGearComplex,
  faInfoCircle as farInfoCircle,
  faPieChart as farPieChart,
  faRightLeftLarge as farRightLeftLarge,
  faShieldCheck as farShieldCheck,
  faSlotMachine as farSlotMachine,
  faStore as farStore,
  faTrash as farTrash,
  faUser as farUser,
  faUserPlus as farUserPlus,
  faUsers as farUsers,
} from '@fortawesome/pro-regular-svg-icons'; // Regular icons
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons';

// Regular icons - 'far'
const REGULAR_ICONS = [
  farAnglesUpDown,
  farArrowLeft,
  farCashRegister,
  farCheck,
  farCheckCircle,
  farChevronDown,
  farChevronUp,
  farClose,
  farCopy,
  farExclamationCircle,
  farExclamationTriangle,
  farEye,
  farEyeSlash,
  farFileExport,
  farFileLines,
  farRightLeftLarge,
  farGearComplex,
  farInfoCircle,
  farPieChart,
  farShieldCheck,
  farSlotMachine,
  farStore,
  farUser,
  farUserPlus,
  farUsers,
  farTrash,
  farGear,
];

// Solid icons - 'fas'
const SOLID_ICONS = [fasTimes];

@Injectable({
  providedIn: 'root',
})
export class FaIconRegistryService {
  private readonly faIconLibrary = inject(FaIconLibrary);

  public registerIcons(iconDefinitions: IconDefinition[]): void {
    this.faIconLibrary.addIcons(...iconDefinitions);
  }
}

export function registerIcons(registry: FaIconRegistryService): () => void {
  return () => registry.registerIcons([...REGULAR_ICONS, ...SOLID_ICONS]);
}
