import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { SidebarMenuService } from './sidebar-menu.service';
import { Memoized } from '@acetech-development/utilities/core';
import { MenuItem } from './menu-item.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserRolesModel } from '../../../../core/domain/users/models/users.model';
import { AUTH_DATA_SELECTORS } from '../../../../state/authentication/auth.selectors';
import { cache } from '@acetech-development/utilities/rxjs';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    // Project
    MenuItemComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  private readonly sideBarMenuService = inject(SidebarMenuService);
  private readonly store = inject(Store);

  @Memoized
  public get mainUserRole$(): Observable<UserRolesModel | undefined> {
    return this.store.select(AUTH_DATA_SELECTORS.selectUserRoles).pipe(
      cache(),
    );
  }

  @Memoized
  public get menuItems(): MenuItem[] {
    return this.sideBarMenuService.menuItems;
  }

  @Memoized
  public get bottomMenuItems(): MenuItem[] {
    return this.sideBarMenuService.sideBarBottomMenuItems;
  }
}
