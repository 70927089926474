import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { APP_EFFECTS, APP_REDUCERS, FEATURE_KEY } from '../presentation/state';
import { DEV_MODULES, META_REDUCERS, STORE_CONFIG } from '../presentation/state/dev-tools';
import { provideToastr } from 'ngx-toastr';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../presentation/core/auth/services/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideNgxErrorsConfig } from '@ngspot/ngx-errors';
import { ROUTES } from '../app.routes';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from '../presentation/feature/shared/constants/custom-date-formats.const';
import { CustomDateAdapter } from '../presentation/feature/shared/services/custom-date-adapter.service';
import { FaIconRegistryService, registerIcons } from '../presentation/feature/shared/components/fa-icon/fa-icon-registry.service';

export const APPCONFIG: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(ROUTES),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    importProvidersFrom(
      StoreModule.forRoot({ router: routerReducer }, { metaReducers: META_REDUCERS, ...STORE_CONFIG }),
      BrowserAnimationsModule,
      EffectsModule.forRoot([]),
      StoreRouterConnectingModule.forRoot(),
      EffectsModule.forFeature(APP_EFFECTS),
      StoreModule.forFeature(FEATURE_KEY, APP_REDUCERS),
      ...DEV_MODULES,
      MatDatepickerModule,
      MatNativeDateModule,
    ),
    provideToastr({
      timeOut: 2500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    provideNgxErrorsConfig({
      showErrorsWhenInput: 'dirty',
      showMaxErrors: 1,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: registerIcons,
      deps: [FaIconRegistryService],
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter }, // Use the custom adapter
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }, // Apply custom date formats
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
};
