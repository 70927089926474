import { Directive, ElementRef, EventEmitter, HostListener, inject, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() public readonly clickOutside = new EventEmitter<void>();

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  @HostListener('document:click', ['$event.target'])
  public onClick(target: EventTarget): void {
    const clickedInside: boolean = this.elementRef.nativeElement.contains(target as Node);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
