import { Injectable } from '@angular/core';
import { NAVIGATION_PATH } from '../../constants/navigation-paths.const';
import { MenuItem } from './menu-item.model';

@Injectable({
  providedIn: 'root',
})
export class SidebarMenuService {
  public readonly menuItems: MenuItem[] = [
    { text: 'Agenții', icon: 'store', url: NAVIGATION_PATH.AGENCIES, hiddenFromOperator: false },

    {
      text: 'Registre Pariuri',
      icon: 'cash-register',
      url: NAVIGATION_PATH.BET_REGISTER,
      hiddenFromOperator: false,
    },

    {
      text: 'Tipuri de Tranzacții',
      icon: 'right-left-large',
      url: NAVIGATION_PATH.TRANSACTIONS,
      hiddenFromOperator: false,
    },
    {
      text: 'Documente',
      icon: 'file-lines',
      url: NAVIGATION_PATH.DOCUMENT,
      hiddenFromOperator: false,
    },
  ];

  public readonly sideBarBottomMenuItems: MenuItem[] = [
    {
      text: 'Setări',
      icon: 'gear-complex',
      hiddenFromOperator: false,
    },
    {
      text: 'Utilizatori',
      icon: 'users',
      url: NAVIGATION_PATH.USERS,
      hiddenFromOperator: true,
    },
  ];
}
