import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { AUTH_DATA_ACTIONS } from './presentation/state/authentication/auth.actions';
import { Memoized } from '@acetech-development/utilities/core';
import { Observable } from 'rxjs';
import { AUTH_DATA_SELECTORS } from './presentation/state/authentication/auth.selectors';
import { cache } from '@acetech-development/utilities/rxjs';
import { MainLayoutComponent } from './presentation/feature/shared/components/main-layout/main-layout.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    RouterOutlet,
    // Project
    MainLayoutComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly store: Store,
  ) {
    this.store.dispatch(AUTH_DATA_ACTIONS.HydrateTokens());
  }

  @Memoized
  public get isAuthenticated$(): Observable<boolean> {
    return this.store.select(AUTH_DATA_SELECTORS.selectIsAuthenticated).pipe(
      cache(),
    );
  }
}
