import { CurrentUserModel } from '../../feature/shared/models/user.model';

export interface AuthDataState {
  isAuthenticated: boolean;
  token: string | undefined;
  refreshToken: string | undefined;
  tokenExpiration: string | undefined;
  refreshTokenExpiration: string | undefined;
  userData: CurrentUserModel | undefined;
}

export const INITIAL_AUTH_DATA_STATE: AuthDataState = {
  isAuthenticated: false,
  token: undefined,
  refreshToken: undefined,
  tokenExpiration: undefined,
  refreshTokenExpiration: undefined,
  userData: undefined,
};
