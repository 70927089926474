<a [routerLink]="url" routerLinkActive="active" (isActiveChange)="onRouterLinkActive($event)">
  <div class="flex flex-row flex-1 h-10 gap-2 cursor-pointer">
    <div class="flex flex-row flex-1 pl-7">
      <div class="flex flex-row flex-1 h-full gap-2 pl-[1px]">
        <!-- Sub menu indicator -->
        <div
          class="h-full w-[1px] bg-[#FFFFFF80]"
          [ngClass]="{ 'w-[3px] -ml-[1px] shadow-[0px_0px_4px_1px_#FFFFFF80] !bg-white': isActive$ | async }"
        ></div>
        <!-- Content -->
        <div
          class="flex flex-row flex-1 items-center h-full rounded py-[1px] px-3 text-sm font-medium drop-shadow-sm hover:bg-active-menu-gradient"
          [ngClass]="{ 'bg-active-menu-gradient !font-bold border border-[#FFFFFF33]': isActive$ | async }"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</a>
