import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  public override parse(value: string): Date | null {
    if (value.includes('/')) {
      const [day, month, year] = value.split('/');
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return super.parse(value);
  }

  public override format(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
