import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Memoized } from '@acetech-development/utilities/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CurrentUserModel } from '../../models/user.model';
import { cache } from '@acetech-development/utilities/rxjs';
import { AUTH_DATA_SELECTORS } from '../../../../state/authentication/auth.selectors';
import { Store } from '@ngrx/store';
import { AsyncPipe, NgClass } from '@angular/common';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { AppFaIconComponent } from '../fa-icon/fa-icon.component';
import { AUTH_DATA_ACTIONS } from '../../../../state/authentication/auth.actions';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { animate, style, transition, trigger } from '@angular/animations';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { RouterLink } from '@angular/router';
import { NAVIGATION_PATH } from '../../constants/navigation-paths.const';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    NgClass,
    RouterLink,
    // Project
    AppFaIconComponent,
    ClickOutsideDirective,
    MenuItemComponent,
    SidebarComponent,
    ProfilePictureComponent,
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.css',
  animations: [
    trigger('userMenuAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [animate('75ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
})
export class MainLayoutComponent {
  public readonly NAVIGATION_PATH = NAVIGATION_PATH;

  private readonly store = inject(Store);

  private readonly showUserMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public toggleUserMenu(): void {
    const currentValue = this.showUserMenuSubject.value;

    this.showUserMenuSubject.next(!currentValue);
  }

  public closeUserMenu(): void {
    this.showUserMenuSubject.next(false);
  }

  public logout(): void {
    this.store.dispatch(AUTH_DATA_ACTIONS.Logout());
  }

  @Memoized
  public get userDetails$(): Observable<CurrentUserModel | undefined> {
    return this.store.select(AUTH_DATA_SELECTORS.selectUserData).pipe(
      cache(),
    );
  }

  @Memoized
  public get currentUserRole$(): Observable<string> {
    return this.store.select(AUTH_DATA_SELECTORS.selectUserRoles).pipe(
      map((roles) => {
        if (roles?.isSuperAdmin) {
          return 'Super Admin';
        }

        if (roles?.isAdmin) {
          return 'Admin';
        }

        if (roles?.isOperator) {
          return 'Operator';
        }

        return 'No Role';
      }),
      cache(),
    );
  }

  @Memoized
  public get showUserMenu$(): Observable<boolean> {
    return this.showUserMenuSubject.asObservable();
  }
}
