<div class="flex flex-col">
  <a [routerLink]="url" routerLinkActive="active" (isActiveChange)="onRouterLinkActive($event)" (click)="toggleMenu()">
    <div class="flex flex-row h-10 gap-2 cursor-pointer">
      <!-- Is active indicator -->
      <div class="w-1 h-full rounded-e" [ngClass]="{ 'shadow-[0px_0px_4px_1px_#FFFFFF80] bg-white': isActive$ | async }"></div>
      <div
        class="flex flex-1 flex-row justify-between items-center rounded p-2 drop-shadow-sm hover:bg-active-menu-gradient"
        [ngClass]="{ 'bg-active-menu-gradient border border-[#FFFFFF33]': isActive$ | async }"
      >
        <div class="flex flex-row gap-2 items-center">
          <app-fa-icon
            class="font-light drop-shadow"
            [iconName]="menuIcon"
            [ngClass]="{ 'drop-shadow-[0px_0px_4px_0px_#FFFFFF] !font-normal': isActive$ | async }"
          />
          <div
            class="text-sm font-medium drop-shadow"
            [ngClass]="{ 'drop-shadow-[0px_0px_2px_0px_#FFFFFF80] !font-bold': isActive$ | async }"
          >
            {{ text }}
          </div>
        </div>

        @if (submenuItems) {
          <app-fa-icon
            [iconName]="'chevron-down'"
            class="font-black text-xs drop-shadow transition-transform duration-300 ease-in-out"
            [ngClass]="{ 'rotate-180': isActive$ | async }"
          />
        }
      </div>
    </div>
  </a>

  @if (submenuItems) {
    <div class="pt-1">
      <div
        class="grid grid-rows-[0fr] transition-all duration-300 ease-in-out"
        [ngClass]="{ 'grid-rows-[1fr]': isActive$ | async, 'grid-rows-[0fr]': !(isActive$ | async) }"
      >
        <ul class="overflow-hidden">
          @for (item of submenuItems; track $index) {
            <li>
              <app-sub-menu-item [text]="item.text" [url]="item.url" />
            </li>
          }
        </ul>
      </div>
    </div>
  }
</div>
