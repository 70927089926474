import { Memoized } from '@acetech-development/utilities/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sub-menu-item',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    NgClass,
    RouterLink,
    RouterLinkActive,

    // Project
  ],
  templateUrl: './sub-menu-item.component.html',
  styleUrl: './sub-menu-item.component.css',
})
export class SubMenuItemComponent {
  @Input({ required: true }) public text!: string;
  @Input({ required: true }) public url!: string;

  private readonly isActiveSubject = new BehaviorSubject<boolean>(false);

  public onRouterLinkActive(isActive: boolean): void {
    this.isActiveSubject.next(isActive);
  }

  @Memoized
  public get isActive$(): Observable<boolean> {
    return this.isActiveSubject.asObservable();
  }
}
