import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AUTH_DATA_SELECTORS } from '../../../state/authentication/auth.selectors';
import { NAVIGATION_PATH } from '../../../feature/shared/constants/navigation-paths.const';

export const loggedInGuard: CanActivateFn = (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(AUTH_DATA_SELECTORS.selectIsAuthenticated).pipe(
    map((isAuthenticated) => (!isAuthenticated ? true : router.createUrlTree([NAVIGATION_PATH.AGENCIES]))),
  );
};
