import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Memoized } from '@acetech-development/utilities/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);

  @Memoized
  public get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  public setLoading(loading: boolean): void {
    this.loadingSubject.next(loading);
  }
}
